import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

import CommonModal from "../commonModal";
import UserInfo from "../userInfo";
import { ProductsIcon } from "../../assets/images/icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { connectionActiveTransations } from "../../redux/services/connection";
import OrderStatusButtons from "../orderStatusButtons";
import UserInfoSkeleton from "../skeletons/UserInfoSkeleton";

interface Props {
  open: boolean;
  close: () => void;
  blockUnblockRemoveConnection: ({ action }: { action: string }) => void;
  connectionId: number;
  actionLoading: boolean;
  type: string
}

interface OrderDataProps {
  company: {
    companyId: number,
    name: string,
    profileImg: string,
    createdBy: {
      userId: number,
      firstName: string,
      lastName: string
    }
  },
  placeOrdersResult: {
    orderId: number,
    refId: string,
    orderNumber: string,
    totalDiscount: number,
    subtotal: number,
    grandTotal: number,
    status: string,
    createdOn: string,
    inProgressOn: string,
    acceptedOn: string,
    dispatchedOn: string,
    dispatchesCount: number,
    orderItemsCount: number
  }[],
  receivedOrdersResult: {
    orderId: number,
    refId: string,
    orderNumber: string,
    totalDiscount: number,
    subtotal: number,
    grandTotal: number,
    status: string,
    createdOn: string,
    inProgressOn: string,
    acceptedOn: string,
    dispatchedOn: string,
    dispatchesCount: number,
    orderItemsCount: number
  }[],
  remainingPlacedOrderCount: number,
  remainingReceivedOrderCount: number
}

interface fetchOrderType {
  connectionId: number,
  placedOrderslimit: number,
  receivedOrderslimit: number,
}

const ConnectionConfirmationModal = ({ blockUnblockRemoveConnection, open, close, connectionId, actionLoading, type }: Props) => {
  const [ordersData, setOrdersData] = useState<OrderDataProps>({} as OrderDataProps);
  const [loading, setLoading] = useState<{ initialData: boolean, moreData: boolean }>({
    initialData: false,
    moreData: false,
  });
  const scrollRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchOrders = ({ connectionId, placedOrderslimit, receivedOrderslimit }: fetchOrderType) => {
    if (connectionId) {
      const payload = {
        id: connectionId,
        placedOrderslimit: placedOrderslimit,
        receivedOrderslimit: receivedOrderslimit,
      };
      dispatch(connectionActiveTransations(payload)).then((result => {
        if (result.payload.status === 200) {
          setOrdersData(result.payload.data);
        }
      })).finally(() => setLoading({ initialData: false, moreData: false }));
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [ordersData]);

  useEffect(() => {
    setLoading((prev) => ({ ...prev, initialData: true }));
    fetchOrders({
      connectionId: connectionId,
      placedOrderslimit: 2,
      receivedOrderslimit: 2,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, connectionId]);

  const handleViewMoreOrders = (type: string) => {
    if (connectionId) {
      setLoading((prev) => ({ ...prev, moreData: true }));
      fetchOrders({
        connectionId: connectionId,
        placedOrderslimit: type === "PlaceOrder" ? 0 : 2,
        receivedOrderslimit: type === "ReceiveOrder" ? 0 : 2,
      });
    }
  };

  const handleOrderShow = (orderId: number, type: string) => {
    if (type === "PlaceOrder") {
      navigate(`/orders/my-orders/${orderId}`);
    } else {
      navigate(`/orders/customer-orders/${orderId}`);
    }
  };

  return (
    <CommonModal
      closeIcon
      open={open}
      close={close}
      className="connection-confirmation-popup">
      <div className="modal-container">
        <div className="header-container">
          {loading.initialData ?
            <UserInfoSkeleton />
            : <UserInfo
              profileImage={ordersData?.company?.profileImg}
              comanyName={ordersData?.company?.name}
              userName={ordersData?.company?.createdBy?.firstName + " " + ordersData?.company?.createdBy?.lastName}
            />}
          <div className="header-content-wrapper">
            {loading.initialData ? <div className="loading-container"><Spin /></div> :
              <>
                <div className="header-content">
                  <h5 className="modal-header-title">Want to <span className="type">{type}</span> Connection?</h5>
                  <p className="content">Are you sure you want to {type?.toLowerCase()} this connection{(ordersData?.placeOrdersResult?.length || ordersData?.receivedOrdersResult?.length)? " and cancel all orders associated with it" : <></> }?</p>
                </div>
                <div className="flex gap-3 alignCenter action-buttons">
                  <button className="cancel-btn" onClick={close}>Cancel</button>
                  <button disabled={actionLoading} className="blockAndCancel-btn" onClick={() => blockUnblockRemoveConnection({ action: type })}>{(ordersData?.placeOrdersResult?.length || ordersData?.receivedOrdersResult?.length) ? `${type} & Cancel Orders` : `Yes, ${type}` }</button>
                </div>
              </>}
          </div>
        </div>
        {(ordersData?.placeOrdersResult?.length || ordersData?.placeOrdersResult?.length) ?
          <div className="order-details-container" ref={scrollRef} >
            {ordersData?.placeOrdersResult?.length ? <div>
              <p className="place-order-title">
                Placed Orders <span className="ongoing-text">(Ongoing)</span>
              </p>
              {ordersData?.placeOrdersResult?.map((item, index) => (
                <div key={index} className="order-card" onClick={() => handleOrderShow(item?.orderId, "PlaceOrder")}>
                  <div className="flex direction-column gap-2">
                    <span className="order-number">{item?.orderNumber ? `Order No: ${item?.orderNumber}` : `Ref Id: ${item?.refId}`}</span>
                    <span className="items">{`${item?.orderItemsCount} items`}</span>
                  </div>
                  <div className="flex direction-column gap-2 textEnd">
                    <span className="order-date">{dayjs(item?.acceptedOn !== null ? item?.acceptedOn : item.createdOn).format("DD MMM YYYY")}</span>
                    <span className="order-status"><OrderStatusButtons status={item?.status} /></span>
                  </div>
                </div>
              ))}
              { }
              {ordersData?.remainingPlacedOrderCount > 0 ? <button disabled={loading.moreData} className="view-order-btn" onClick={() => handleViewMoreOrders("PlaceOrder")}>{<><ProductsIcon /> View all orders</>}</button> : ""}
            </div> : ""}
            {ordersData?.receivedOrdersResult?.length ? <div>
              <p className="place-order-title">
                Received Orders <span className="ongoing-text">(Ongoing)</span>
              </p>
              {ordersData?.receivedOrdersResult?.map((item, index) => (
                <div key={index} className="order-card" onClick={() => handleOrderShow(item?.orderId, "ReceiveOrder")}>
                  <div className="flex direction-column gap-2">
                    <span className="order-number">{`Order No: ${item?.orderNumber}`}</span>
                    <span className="items">{`${item?.orderItemsCount} items`}</span>
                  </div>
                  <div className="flex direction-column gap-2 textEnd">
                    <span className="order-date">{dayjs(item?.acceptedOn !== null ? item?.acceptedOn : item.createdOn).format("DD MMM YYYY")}</span>
                    <span className="order-status"><OrderStatusButtons status={item?.status} /></span>
                  </div>
                </div>
              ))}
              {ordersData?.remainingReceivedOrderCount > 0 ? <button disabled={loading.moreData} className="view-order-btn" onClick={() => handleViewMoreOrders("ReceiveOrder")}><ProductsIcon /> View all orders</button> : ""}
            </div> : ""}
          </div> : ""}
      </div>
    </CommonModal>
  );
};

export default ConnectionConfirmationModal;