export const COMPANY_INFO = {
  LINK: "http://biizlinedevops.com/",
};

export const REGEX_STRINGS = {
  EMAIL: /^([\w\\.\\+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/,
  PRICE: /^\d{0,6}(\.\d{0,2})?$/,
  RATE: /^\d{1,6}(\.\d{0,2})?$/,
  QUANTITY: /^\d{0,5}?$/,
  PERCENTAGE: /^\d{0,2}(\.\d{0,2})?$/,
  SPECIAL_CHAR: /^[^a-zA-Z0-9]+$/,
  GST_NUMBER: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
  PHONE_NUMBER: /^[6-9]\d{9}$/,
  NUMBER: /^\d{0,10}$/,
};

export const OPERATER_STRINGS = {
  DIVISION: "/",
  MODULO: "%",
};

export const NUMBERS = {
  1: 1,
  0: 0,
};

export const COMMON_STRINGS = {
  PREFIX: "Prefix",
  SUFFIX: "Suffix",
  SUMMARY: "Summary",
  YEAR: "Year",
  BLOCKED: "Blocked",
  UNBLOCK: "Unblock",
};

export const FILE_RESTRICTIONS = {
  MAX_IMAGE_SIZE: "Image upload size max. 2MB",
};

export const ERROR_STRINGS = {
  DUPLICATE_BULK_RATE: "Duplicate Bulk rates are not Allowed",
  EMPTY_BULK_RATE: "Please Enter Bulk rate",
  INVALID_EMAIL: "Email address must be contain @, ., and domain name",
  GST_MESSAGE: "Please enter valid GST number",
  PHONE_NUMBER: "Please enter a valid number",
  ALTERNATE_PHONE_NUMBER: "Please enter a valid phone number",
  SOMETHING_WRONG: "Something Went Wrong",
  PIN_CODE: "Please enter 6 digit pincode",
  LOCATION: "Please enter correct location",
  COMPANY_NAME: "Please enter company name",
  LOGIN_FAIELD: "Login failed",
};

export const INPUT_FIELDS = {
  FIRST_NAME: {
    LABEL: "First Name",
    PLACEHOLDER: "Enter First Name",
  },
  LAST_NAME: {
    LABEL: "Last Name",
    PLACEHOLDER: "Enter Last Name",
  },
  PHONE_NUMBER: {
    LABEL: "Phone Number",
    PLACEHOLDER: "Enter WhatsApp number",
  },
  EMAIL: {
    LABEL: "Email Address",
    PLACEHOLDER: "Enter your email address",
  },
  CREATE_ROLE: {
    LABEL: "Create Role",
    PLACEHOLDER: "Ex. Prduct Manager, Accountant",
  },
};

export const ACCOUNT_SETTINGS = {
  TITLE: "Account Settings",
  TEXT: "Effortlessly organize your customer and vendor orders with ease and efficiency using our solution!",
  CREATE_USER_ROLL_FORM: {
    FORM_TITLE_1: "Update User",
    FORM_TITLE_2: "Create User",
  },
  ROLE_AND_ACCESS_PAGE: {
    TABLE: {
      TITLE: "Give Access",
      HEADING: "Modules",
    },
  },
  PRODUCT_MANAGEMENT_PAGE: {
    PAGE_TITLE: "Product Management",
    TEXT: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    TABLE_TITLE: "Module",
  },
  CONNECTION_PAGE: {
    TITLE: "Connections",
    DESCRIPTION: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  NOTIFICATION_PAGE: {
    TITLE: "Notification",
    DESCRIPTION: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    TABLE_TITLE: "Modules",
  },
  CONNECTION_UNBLOCK_POPUP: {
    TITLE: "Unblock Connection",
    DESCRIPTION: "Are you sure you want to unblock this connection?",
    BUTTON_TEXT_1: "Cancel",
    BUTTON_TEXT_2: "Unblock",
    TABLE_TITLE: "Blocked Connections",
  },
  SUBSCRIPTION_PAGE: {
    TITLE: "Subscription & Billing",
    DESCRIPTION: "Create distinct user roles that align with organisational workflows and security protocols.",
    SUPPORT_BUTTON: "Get Support",
    SUBSCRIPTION_BODY: {
      SUBSCRIPTION_TITLE: "Plans & Features",
    },
  },
  SUBSCRIPTION_FORM: {
    TITLE: "Billing Details",
    TEXT: "Manage your subscriptions with ease and unlock exclusive business tools.",
    SUMMARY_TEXT: "Easily manage your business subscriptions with Biizline. Upgrade to premium plans and unlock advanced features tailored for your business needs. Simplify your workflow and grow your business seamlessly.",
    INPUT_FIELDS: {
      FIRST_NAME: {
        LABEL: "First Name",
        PLACEHOLDER: "Enter First name",
      },
      LAST_NAME: {
        LABEL: "Last Name",
        PLACEHOLDER: "Enter Last name",
      },
      PHONE_NUMBER: {
        LABEL: "Phone Number",
        PLACEHOLDER: "Enter Phone number",
      },
      ALTERNATE_NUMBER: {
        LABEL: "Alternate Phone Number (Optional)",
        PLACEHOLDER: "Enter Alternate phone number",
      },
      EMAIL_ADDRESS: {
        LABEL: "Email Address (Optional)",
        PLACEHOLDER: "Enter Email Address",
      },
      PROMOCODE: {
        LABEL: "Enter Code",
        PLACEHOLDER: "Enter Promo code",
      },
      GST_CODE: {
        LABEL: "GST Number",
        PLACEHOLDER: "Enter GST Number",
      },
      COMPANY_NAME: {
        LABEL: "Company Name",
        PLACEHOLDER: "Enter Company Name",
      },
      PINCODE: {
        LABEL: "Pincode",
        PLACEHOLDER: "Enter Pincode",
      },
      LOCATION: {
        LABEL: "Location",
        PLACEHOLDER: "District, State",
      },
      ADDRESS_LINE: {
        LABEL: "Address Line (Optional)",
        PLACEHOLDER: "Enter your address line",
      },
    },
    BUTTONS: {
      MAKE_PAYMENT: "Make Payment",
      GO_BACK: "Go Back",
    },
    CHECKBOX_INPUT_FIELDS: {
      PROMOCODE: {
        LABEL: "I have a Promo Code",
      },
      GST_CODE: {
        LABEL: "I Need GST Invoice",
      },
    },
    PAYMENT_FOR: "Payment for",
    APPLIED_DISCOUNT: "Applied Discount",
    CHANGE_PLAN: "Change Plan",
    SUMMARY: "Summary",
    SUBTOTAL: "Subtotal",
    IGST: "IGST",
    CGST: "CGST",
    SGST: "SGST",
    GRAND_TOTAL: "Grand Total",
  },
};

export const CONFIRMATION_POPUP_STRINGS = {
  ORDER_MANAGEMENT_PAGE: {
    SAVE_CHANGES: {
      TITLE: "Save Changes",
      TEXT: "Are you sure you want to save your changes?",
      CONFIRM_BUTTON: "Save Changes",
      CANCEL_BUTTON: "Cancel",
    },
  },
};

export const NO_DATA_FOUND_STRINGS = {
  NO_RECORD_DATA: "No Record Found.",
};

export const ALTER_TEXT = {
  PROFILE_IMAGE: "Profile Img",
  SUBSCRIPTION_PLAN_IMG: "plan-image",
};

export enum SubscriptionActivityName {
  PLACE_ORDER = "Place Order",
  CUSTOMERS_ORDER = "Customer's Order",
  SPECIAL_PRICE_LIST = "Special Price List",
  CONNECTIONS = "Connections",
  ADD_PRODUCTS = "Add Products",
  PRODUCT_GROUP = "Product Group",
  COMPANY = "Company",
  PLATFORM = "Platform",
  CHAT = "Chat",
  PARTIAL_DISPATCH = "Partial Dispatch",
  MANAGE_USER = "Manage User",
  DISCOUNT_OFFERS = "Discount & Offers",
  BULK_IMPORT = "Bulk Import",
  BULK_PRICE_UPDATE = "Bulk Price Update",
  ADVANCE_DASHBOARD_ANALYTICS = "Advance Dashboard Analytics"
}

export enum SubscriptionModules {
  ORDER = "Order",
  SPECIAL_PRICE_LIST = "Special Price List",
  CONNECTIONS = "Connections",
  PRODUCTS = "Products",
  COMPANY = "Company",
  PLATFORM = "Platform",
  CHAT = "Chat",
  SETTING = "Setting",
  DISCOUNT_OFFERS = "Discount & Offers",
  DASHBOARD = "Dashboard"
}

export const WARNING_STRINGS = {
  ENTER_CANCEL_REASON: "Please enter a reason to cancel order",
  SELECT_CANCEL_REASON: "Please select a reason to cancel order",
};

export const CANCEL_ORDER_MODAL = {
  TITLE: "Cancel Order",
  TEXT: "Let us know why you want to cancel your order.",
  SAVE_BUTTON: "Submit",
};

export const CHOOSE_VENDOR_MODAL = {
  TITLE: "Choose Vendor",
  TEXT: "Connections.",
  BUTTON_TEXT_1: "Invite Vendor",
  BUTTON_TEXT_2: "Clear All",
  SAVE_BUTTON: "Confirm",
  CANCEL_BUTTON: "Cancel",
};

export const CHOOSE_CUSTOMER_MODAL = {
  TITLE: "Choose Customer",
  TEXT: "Connections.",
  BUTTON_TEXT_1: "Invite Connection",
  BUTTON_TEXT_2: "Clear All",
  SAVE_BUTTON: "Confirm",
  CANCEL_BUTTON: "Cancel",
};

export const CHOOSE_COMPANY_POPUP = {
  TITLE: "Switch company",
  TITLE_2: "Company setting",
  LOGOUT: "Logout",
  ADD_COMPANY: "Add Company",
};

export const LOGOUT_POPUP = {
  TITLE: "Are you sure, you want to Logout?",
  SUCCESS: "Yes, Logout",
  NO: "No",
};

export const UPGRADE_TO_PREMIUM_POPUP = {
  TITLE: "Please Upgrade to Premium",
  TEXT: "Upgrade to Premium and take your experience to the next level with advanced tools and benefits designed to make your work faster, smarter, and more efficient.",
  CANCEL_BUTTON: "Cancel",
  SUCCESS_BUTTON: "Upgrade to Premium",
};