import React, { useEffect } from "react";
import OneSignal from "react-onesignal";

import Routing from "./router/router";
import socket from "./socket";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { useAppSelector } from "./hooks/useAppSelector";
import { registerDeviceToken } from "./redux/services/common";
import { allNotificationData, readAllNotifications } from "./redux/slices/notificationSlice";
import { ThemeProvider } from "./utils/themeContext";
import { NotificationData } from "./components/notification/NotificationTypes";
import "./common.css";
import "./assets/styles/common.scss";
import "react-loading-skeleton/dist/skeleton.css";

function App() {
  const dispatch = useAppDispatch();

  const { userDetails } = useAppSelector((state) => state.common);

  const { userId } = userDetails.data?.user || { userId: 0 };

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID || "",
      safari_web_id: process.env.REACT_APP_ONE_SIGNAL_SAFARI_ID || "",
      notifyButton: {
        enable: false,
      },
      allowLocalhostAsSecureOrigin: true,
    });
    OneSignal.Notifications.requestPermission();
  }, []);

  function permissionChangeListener(permission: any) {
    if (permission) {
      const id = OneSignal.User.PushSubscription.id;
      if (id) {
        OneSignal.login(id);
        if (id && userId) {
    
          const payload = {
            token: id,
            from: "Web",
          };
          dispatch(registerDeviceToken({ id: userId, payload: payload }));
        }
      }
    }
  }

  useEffect(() => {
    OneSignal.Notifications.addEventListener("permissionChange", permissionChangeListener);

    return () => {
      OneSignal.Notifications.removeEventListener("permissionChange", permissionChangeListener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    socket.on("connection", () => {
      console.info("Connected to socket server");
    });

    socket.on("notificationHistoryResponse", (data: NotificationData[]) => {
      dispatch(allNotificationData(data));
    });

    socket.on("readAllNotificationResponse", (data) => {
      if (data) {
        dispatch(readAllNotifications());
      }
    });

    return () => {
      socket.off("notificationHistoryResponse");
      socket.off("readAllNotificationResponse");
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <ThemeProvider>
      <Routing />
    </ThemeProvider>
  );
}

export default App;
