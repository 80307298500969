import React, { useEffect, useRef, useState } from "react";

import CommonModal from "../../components/commonModal";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import useMessageHook from "../../hooks/useMessageHook";
import { getOtpforUpdate, updateVerifyOtp } from "../../redux/services/settings";
import { ERROR_STRINGS } from "../../utils/constant";

interface Props {
  handleUpdate?: () => void;
  open: boolean;
  close: () => void;
  phoneNumber: string;
}

const VerifyOtpModal = ({ handleUpdate, open, close, phoneNumber }: Props) => {
  const [otp, setOtp] = useState<string[]>(Array(5).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState<number>(45);
  const [canResend, setCanResend] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<string>("");
  const [otpSuccess, setOtpSuccess] = useState<boolean>(false);
  const firstOtpInputRef = useRef<HTMLInputElement>(null);

  const { showMessage, contextHolder } = useMessageHook();

  const dispatch = useAppDispatch();

  useEffect(() => {
    // Focus on the first OTP input when the component mounts
    if (firstOtpInputRef.current) {
      firstOtpInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  const validateOtp = () => {
    if (otp.includes("")) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (otp.length === 5 && validateOtp()) {
      handleVerifyOTP();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const handleVerifyOTP = async () => {
    setIsLoading(true);
    try {
      const payload = {
        phoneNumber: phoneNumber,
        otp: otp.join(""),
      };
      await dispatch(updateVerifyOtp(payload)).then((result) => {
        if (result?.payload?.status === 200) {
          setOtpSuccess(true);
          close();
          handleUpdate && handleUpdate();
          
        } else {
          setOtpError(result.payload?.message || ERROR_STRINGS.LOGIN_FAIELD);
          showMessage("error", result?.payload.message || ERROR_STRINGS.LOGIN_FAIELD);
        }
      }).catch((error) => console.error(ERROR_STRINGS.SOMETHING_WRONG));
    } catch (error: any) {
      setOtpError(error.response?.data?.message || ERROR_STRINGS.LOGIN_FAIELD);
      showMessage("error", error.response?.data.message || ERROR_STRINGS.LOGIN_FAIELD);

    } finally {
      setOtpSuccess(false);
      setIsLoading(false);
    }
  };

  const handleChangeNumber = () => {
    close();
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5 - 1) {
        const nextSibling = document.querySelector<HTMLInputElement>(`#otp-input-${index + 1}`);
        nextSibling?.focus();
      }
      setOtpError("");
      setOtpSuccess(false);
    }
  };
  
  
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    // Get the pasted data and ensure it is at most 5 characters long
    const pasteData = e.clipboardData?.getData("text")?.slice(0, 5);

    // Check if the paste data is numeric and has up to 5 characters
    const isValidPaste = /^[0-9]{1,5}$/.test(pasteData);

    if (isValidPaste) {
      // Create an OTP array from the paste data, filling with empty strings if less than 6 digits
      const newOtp = pasteData.split("").concat(Array(5 - pasteData?.length).fill(""));
      setOtp(newOtp.length === 5 ? newOtp : Array(5).fill(""));

      // Move focus to the last filled input
      const lastFilledIndex = pasteData?.length;
      const lastFilledInput = document.querySelector<HTMLInputElement>(`#otp-input-${lastFilledIndex}`);
      lastFilledInput?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const currentInput = document.querySelector<HTMLInputElement>(`#otp-input-${index}`);

    if (!currentInput) return;

    if (e.key === "Backspace" && !otp[index]) {
      const prevSibling = document.querySelector<HTMLInputElement>(`#otp-input-${index - 1}`);
      prevSibling?.focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      e.preventDefault();
      const prevSibling = document.querySelector<HTMLInputElement>(`#otp-input-${index - 1}`);
      prevSibling?.focus();
      prevSibling?.select();
    } else if (e.key === "ArrowRight" && index < 5 - 1) {
      e.preventDefault();
      const nextSibling = document.querySelector<HTMLInputElement>(`#otp-input-${index + 1}`);
      nextSibling?.focus();
      nextSibling?.select();
    }
  };

  const handleResend = () => {
    dispatch(getOtpforUpdate(phoneNumber));
    setOtpError("");
    setTimer(45);
    setCanResend(false);
    setOtp(Array(5).fill(""));
  };

  return (
    <CommonModal open={open} close={close} className="verify-otp-model">
      {contextHolder}
      <div className="otp-container">
        <div className="flex direction-column white-box">
          <h2 className="title-2 fontWeight-600">Enter OTP</h2>
          <span className="otp-sent-text">OTP sent to +91 {phoneNumber}.
            <button className="change-number" onClick={handleChangeNumber}> Change</button>
          </span>
          <div className="flex gap-4 justifyCenter">
            {otp && otp.map((value, index) => (
              <input
                disabled={isLoading}
                ref={index === 0 ? firstOtpInputRef : null}
                key={index}
                id={`otp-input-${index}`}
                type="text"
                inputMode="numeric"
                maxLength={1}
                placeholder="-"
                value={value}
                onChange={(e) => handleChange(e, index)}
                onPaste={handlePaste}
                onKeyDown={(e) => handleKeyDown(e, index)}
                className={`otp-input ${otpError ? "error" : ""} ${otpSuccess ? "green-border" : ""}`}
                autoComplete="off"
              />
            ))}
          </div>
          {otpError && <span className="otp-error-text">{otpError}</span>}
          {canResend && <span className="resend-text">Didn&apos;t receive code? <button onClick={handleResend}>Resend</button></span>}
          {!canResend && <span className="resend-text">Valid till <button>00:{timer.toString().padStart(2, "0")}  </button></span>}
        </div>
      </div>
    </CommonModal>
  );
};

export default VerifyOtpModal;