import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isTablet } from "react-device-detect";

import { detectDevice } from "../utils/helper";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (detectDevice()?.type === "Mobile") {
      if (detectDevice()?.os === "iOS") { ///iPad|iPhone|iPod/
        window.history.back();
        window.location.href = "https://apps.apple.com/in/app/biizline/id6599846985";
      } else {
        window.history.back();
        window.location.href = "https://biizline.com/coming-soon";
      }
    } else if (isTablet) {
      window.location.href = "https://biizline.com/coming-soon";
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location, detectDevice()?.type]);

  return token && detectDevice()?.type === "Desktop" ? <Outlet /> : <Navigate to="/home" />;
};

export default PrivateRoute;