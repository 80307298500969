import React, { useEffect, useState } from "react";
import { Image, Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { InfoCircleOutlined } from "@ant-design/icons";

import useMessageHook from "../../hooks/useMessageHook";
import { compressImage } from "../../utils/helper";
import { FILE_RESTRICTIONS } from "../../utils/constant";

interface IProps {
  getFile?: any;
  value?: UploadFile[];
}

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const FileUpload = ({ getFile, value }: IProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { showMessage, contextHolder } = useMessageHook();

  useEffect(() => {
    if (value?.length) {
      setFileList(value);
    } else {
      setFileList([]);
    }
  }, [value]);

  const compressFile = async (files: any) => {
    let compressedImgFile: any = files[0]?.originFileObj;
    if (Math.round((files[0]?.originFileObj.size / 1024)) > 400) {
      try {
        compressedImgFile = await compressImage(files[0]?.originFileObj, 400);
      } catch (error) {
        showMessage("error", "Image compression failed");
        return;
      }
    }
    const UpdatedFile = files.map((item:any) => ({ ...item, originFileObj: compressedImgFile }));
    getFile && getFile(UpdatedFile);
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList?.[0]?.originFileObj) {
      const isJpgOrPng = newFileList?.[0]?.originFileObj?.type === "image/jpeg" || newFileList?.[0]?.originFileObj?.type === "image/jpg" || newFileList?.[0]?.originFileObj?.type === "image/png";
      if (!isJpgOrPng) {
        showMessage("error", "You can only upload JPG/PNG file!");
        return;
      }

      const sizeLimit = 2048;
      if ((newFileList?.[0]?.originFileObj.size / 1024) > sizeLimit) {
        showMessage("error", "Image must be smaller than 2MB!");
        return;
      }
      compressFile(newFileList);
      const file: any = newFileList;
      const formData = new FormData();
      formData.append("companyProfile", file[0]);
      setFileList(newFileList);
    } else {
      setFileList([]);
      getFile("");
    }
  };

  const onPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const customRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("Image Preview");
    }, 0);
  };

  return (
    <div className="flex alignCenter gap-4">
      {contextHolder}
      <ImgCrop rotationSlider>
        <Upload
          listType="picture-circle"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          className={fileList?.length ? "file-upload uploaded" : "file-upload"}
          customRequest={customRequest}
          accept="image/png, image/jpeg"
        >
          {!fileList.length ? <div className="logo-text-data">LOGO</div> : ""}
        </Upload>
      </ImgCrop>
      <div className="img-support-text flex alignCenter gap-1">
        <InfoCircleOutlined />
        <span>{FILE_RESTRICTIONS.MAX_IMAGE_SIZE}</span>
      </div>
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </div>
  );
};

export default FileUpload;
