import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CommonModal from "../../components/commonModal";
import { Button, InputField } from "../../components";
import { ACCOUNT_SETTINGS, ALTER_TEXT, COMMON_STRINGS, ERROR_STRINGS, OPERATER_STRINGS, REGEX_STRINGS, SubscriptionModules } from "../../utils/constant";
import { EmailOutlinedIcon, PhoneOutlinedIcon, PromoCodeIcon } from "../../assets/images";
import { formatRupees } from "../../utils/helper";
import { useAppSelector } from "../../hooks/useAppSelector";
import WpIcon from "../../assets/images/wpIcon";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { checkGSTCode, checkPromoCode, createSubscriptionPayment, getPaymentDetailsSummary, getPlateformSubscriptionPayloadData, getSubscriptionPayloadData, getSubscriptionPlanDetails, verifyRazorpayPayment } from "../../redux/services/subscription";
import useMessageHook from "../../hooks/useMessageHook";
import limit from "../../utils/limits.json";
import { getCompanyDetails } from "../../redux/services/company";
import { getAddressByPincode } from "../../redux/services/address";

interface ISubscribeProps {
  open: boolean;
  close: (e: boolean) => void;
}

const SubscribeForm = ({ open, close }: ISubscribeProps) => {
  const [value, setValue] = useState<any>({});
  const [error, setError] = useState<any>({});
  const [isActive, setIsActive] = useState({
    promocode: false,
    gst: false,
  });
  const { showMessage, contextHolder } = useMessageHook();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userDetails } = useAppSelector((state) => state.common);
  const { planDetailsSummary } = useAppSelector((state) => state.subscription);

  useEffect(() => {
    checkIsDisabled();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handlePayment = async (payload: any) => {
    // Request backend to create a Razorpay order
    const { payload: data } = await dispatch(createSubscriptionPayment({
      id: planDetailsSummary.data?.planDetails.subscriptionId || 0,
      payload: payload,
    }));

    const { data: { id: order_id, currency } } = data;
    const amount = payload?.paymentData?.grandTotal || 0;
    // Initialize Razorpay options
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KAY, // From environment variables //rzp_live_IW60jLkplZs9FC
      amount: amount * 100,
      currency,
      order_id,
      name: userDetails?.data?.company?.name,
      description: `${ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.PAYMENT_FOR} ${planDetailsSummary?.data?.planDetails?.planName}`,
      handler: async function (response: any) {
        // Verify payment by sending details to the backend
        const paymentData = {
          orderId: response.razorpay_order_id,
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature,
        };
        setIsLoading(true);
        dispatch(verifyRazorpayPayment(paymentData)).then((result) => {
          if (result?.payload?.status === 200) {
            setIsLoading(false);
            close(false);
            const PDFName = result?.payload?.data?.invoiceUrl?.split("/");
            console.log(PDFName);
            fetch("SamplePDF.pdf").then((response) => {
              response.blob().then((blob) => {
                // Setting various property values
                const alink = document.createElement("a");
                alink.href = result?.payload?.data?.invoiceUrl;
                alink.download = "SamplePDF.pdf";
                alink.target = "blank";
                alink.click();
              }).catch((error) => {
                showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
              });
            }).catch((error) => {
              showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
            });
            dispatch(getPlateformSubscriptionPayloadData({ module: SubscriptionModules.PLATFORM }));
            dispatch(getSubscriptionPayloadData({ module: SubscriptionModules.COMPANY }));
            dispatch(getSubscriptionPlanDetails());
            dispatch(getCompanyDetails());
            navigate("/");
          } else {
            showMessage("error", result?.payload?.message || ERROR_STRINGS.SOMETHING_WRONG);
            setIsLoading(false);
          }
        }).catch((error) => {
          showMessage("error", error);
          setIsLoading(false);
        });
      },
      prefill: {
        name: userDetails?.data?.user?.firstName + " " + userDetails?.data?.user?.lastName,
        email: userDetails?.data?.user?.email,
        contact: userDetails?.data?.user?.phoneNo,
      },
      theme: {
        color: "#FFBC0D",
      },
    };

    // Open Razorpay checkout
    const rzp1 = new (window as any).Razorpay(options);
    rzp1.open();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verifyPromoCode = async (val: string) => {
    setIsLoading(true);
    await dispatch(checkPromoCode({ promocode: val })).then((result) => {
      if (result?.payload?.status === 200) {
        setIsActive({
          ...isActive,
          promocode: true,
        });
        const payload = {
          needGSTInvoice: value?.gst_number?.length ? isActive.gst : false,
          promoCode: val,
        };
        if (isActive.gst && value?.gst_number?.length) {
          Object.assign(payload, { gstNumber: value?.gst_number });
        }
        dispatch(getPaymentDetailsSummary({
          id: planDetailsSummary?.data?.planDetails.subscriptionId || 0,
          payload: payload,
        }));
        setIsLoading(false);
        checkIsDisabled();
        setError((prev: any) => ({
          ...prev,
          code: false,
        }));
      } else {
        showMessage("error", result?.payload?.message);
        setIsActive({
          ...isActive,
          promocode: false,
        });
        setIsLoading(false);
      }
    }).catch((error) => {
      showMessage("error", error);
      setIsLoading(false);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verifyGSTNumber = async (val: string) => {
    setIsLoading(true);
    if (val?.length === 15) {
      await dispatch(checkGSTCode({ GST: val })).then((result) => {
        if (result?.payload?.status === 200) {
          setValue((prev: any) => ({
            ...prev,
            address_line: result?.payload?.data?.addressLine,
            district: result?.payload?.data?.district,
            state: result?.payload?.data?.state,
            location: `${result?.payload?.data?.district}, ${result?.payload?.data?.state}`,
            pincode: result?.payload?.data?.pincode,
            company_name: result?.payload?.data?.name,
          }));
          checkIsDisabled();
          setError((prev: any) => ({
            ...prev,
            gst_number: false,
            gst_number_message: "",
          }));
          setIsActive({
            ...isActive,
            gst: true,
          });
          const payload = {
            needGSTInvoice: true,
            gstNumber: val,
          };
          if (isActive?.promocode) {
            Object.assign(payload, { promoCode: value?.code });
          }
          dispatch(getPaymentDetailsSummary({
            id: planDetailsSummary?.data?.planDetails.subscriptionId || 0,
            payload: payload,
          }));
          setIsLoading(false);
        } else {
          setValue((prev: any) => ({
            ...prev,
            address_line: "",
            district: userDetails?.data?.company?.district,
            state: userDetails?.data?.company?.state,
            location: `${userDetails?.data?.company?.district}, ${userDetails?.data?.company?.state}`,
            pincode: userDetails?.data?.company?.pincode || "",
            company_name: userDetails?.data?.company?.name || "",
          }));
          showMessage("error", result?.payload?.message);
          setIsActive({
            ...isActive,
            gst: false,
          });
        }
      }).catch((error) => {
        setIsLoading(false);
        showMessage("error", error);
      });
    }
  };

  useEffect(() => {
    setValue((prev: any) => ({
      ...prev,
      company_name: userDetails?.data?.company?.name || "",
      phone_number: userDetails?.data?.user?.phoneNo || "",
      f_name: userDetails?.data?.user?.firstName || "",
      l_name: userDetails?.data?.user?.lastName || "",
      email: userDetails?.data?.user?.email || "",
      address_line: "",
      district: userDetails?.data?.company?.district,
      state: userDetails?.data?.company?.state,
      location: `${userDetails?.data?.company?.district}, ${userDetails?.data?.company?.state}`,
      pincode: userDetails?.data?.company?.pincode,
    }));
  }, [userDetails, open]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: val, checked } = e.target;
    if (name === "code") {
      setValue((prev: any) => ({
        ...prev,
        code: val,
      }));
      if (val?.length === 6) verifyPromoCode(val);
    }
    if (name === "gst_number") {
      setValue((prev: any) => ({
        ...prev,
        gst_number: val,
      }));
      if (REGEX_STRINGS.GST_NUMBER.test(val)) verifyGSTNumber(val);
      else if (val?.length === 15 && !REGEX_STRINGS.GST_NUMBER.test(val)) {
        Object.assign(error, { gst_number_message: ERROR_STRINGS.GST_MESSAGE });
      } else {
        setError((prev: any) => ({
          ...prev,
          gst_number_message: false,
        }));
      }
    }
    if (name === "gst") {
      dispatch(getPaymentDetailsSummary({
        id: planDetailsSummary?.data?.planDetails.subscriptionId || 0,
        payload: {
          needGSTInvoice: false,
        },
      }));
    }
    if (name === "pincode") {
      setValue((prev: any) => ({
        ...prev,
        pincode: val,
        location: "",
        address_line: "",
      }));
      if (val?.length === 6) {
        const response = await dispatch(getAddressByPincode({ pincode: val }));
        if (response?.payload?.status === 200) {
          setValue((prev: any) => ({
            ...prev,
            pincode: val,
            location: `${response?.payload?.data?.district}, ${response?.payload?.data?.state}`,
            district: response?.payload?.data?.district,
            state: response?.payload?.data?.state,
          }));
          setError((prev: any) => ({
            ...prev,
            pincode: false,
            pincode_message: "",
          }));
        } else {
          setValue((prev: any) => ({
            ...prev,
            pincode: "",
          }));
          setError((prev: any) => ({
            ...prev,
            pincode: true,
            pincode_message: response?.payload?.message,
          }));
          showMessage("error", response?.payload?.message);
        }
      }
    }
    if (name === "alternate_number") {
      setValue((prev: any) => ({
        ...prev,
        alternate_number: REGEX_STRINGS.NUMBER.test(val) ? val : "",
      }));
    } else {
      setValue((prev: any) => ({
        ...prev,
        [name]: name === "gst" || name === "promo_code" ? checked : val,
      }));
      if (name === "gst" || name === "promo_code") {
        setIsActive((prev) => ({
          ...prev,
          [name]: checked,
        }));
      }
      if (!checked && name === "promo_code") {
        setValue((prev: any) => ({
          ...prev,
          promo_code: false,
          code: "",
        }));
        if (REGEX_STRINGS.GST_NUMBER.test(value?.gst_number) && isActive?.gst) verifyGSTNumber(value?.gst_number);
      }
    }
  };

  const handleValidate = () => {
    const errorObj = {};
    setError({});
    if (!value?.f_name) {
      Object.assign(errorObj, { f_name: true });
    }
    if (!value?.l_name) {
      Object.assign(errorObj, { l_name: true });
    }
    if (!value?.phone_number) {
      Object.assign(errorObj, { phone_number: true });
    }
    if (value?.alternate_number?.length) {
      if (!REGEX_STRINGS.PHONE_NUMBER.test(value?.alternate_number)) {
        Object.assign(errorObj, { alternate_number: ERROR_STRINGS.ALTERNATE_PHONE_NUMBER });
      }
    }
    if (value?.email?.length) {
      if (!REGEX_STRINGS.EMAIL.test(value?.email)) {
        Object.assign(errorObj, { email: ERROR_STRINGS.INVALID_EMAIL });
      }
    }
    if (value?.pincode?.length !== 6) {
      Object.assign(errorObj, { pincode: ERROR_STRINGS.PIN_CODE });
    }
    if (!value?.location?.length) {
      Object.assign(errorObj, { location: ERROR_STRINGS.LOCATION });
    }
    if (!value?.company_name?.length) {
      Object.assign(errorObj, { company_name: ERROR_STRINGS.COMPANY_NAME });
    }
    if (value?.gst) {
      if (!value?.gst_number) {
        Object.assign(errorObj, { gst_number: true });
      }
      if (value?.gst_number) {
        if (!REGEX_STRINGS.GST_NUMBER.test(value?.gst_number)) {
          Object.assign(errorObj, { gst_number_message: ERROR_STRINGS.GST_MESSAGE });
        }
      }
    }
    if (value?.promo_code) {
      if (!value?.code?.length) {
        Object.assign(errorObj, { code: true });
      }
      if (value?.code?.length !== 6) {
        Object.assign(errorObj, { code: true });
      }
    }
    if (Object.keys(errorObj)?.length) {
      setError(errorObj);
      return false;
    }
    else {
      return true;
    }
  };

  const handleSubmit = () => {
    const paymentDetails = planDetailsSummary?.data?.billingDitails;
    if (handleValidate()) {
      const payload = {
        paymentData: paymentDetails,
        userData: {
          name: value?.company_name || "",
          addressLine: value?.address_line || "",
          district: value?.district || "",
          state: value?.state || "",
          pincode: value?.pincode || "",
          firstName: value?.f_name || "",
          lastName: value?.l_name || "",
          phoneNo: value?.phone_number || "",
          email: value?.email || null,
        },
      };
      handlePayment(payload);
    }
  };

  const handleClose = () => {
    setError({});
    setValue({
      f_name: "",
      l_name: "",
      location: "",
      pincode: "",
      address_line: "",
      district: "",
      state: "",
    });
    close(false);
  };

  const checkIsDisabled = () => {
    if ((isActive?.gst && value?.gst_number?.length !== 15) || (isActive?.promocode && value?.code?.length !== 6) || (error?.gst_number || error?.code)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <CommonModal
      close={() => handleClose()}
      open={open}
      className="subscription-form"
    >
      {contextHolder}
      <div className="flex gap-4 w-100">
        <div className="form-container flex direction-column gap-8 w-100">
          <div className="form-title-container">
            <h2 className="title-2 form-heading">
              {ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.TITLE}
            </h2>
            <p className="caption">{ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.TEXT}</p>
          </div>
          <div className="form-inputs-container flex direction-column gap-6">
            <div className="flex gap-5 name-inputs">
              <InputField
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.FIRST_NAME.LABEL}
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.FIRST_NAME.PLACEHOLDER}
                name="f_name"
                value={value?.f_name}
                required
                onChange={handleChange}
                className={error?.f_name ? "error" : ""}
              />
              <InputField
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.LAST_NAME.LABEL}
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.LAST_NAME.PLACEHOLDER}
                name="l_name"
                value={value?.l_name}
                required
                onChange={handleChange}
                className={error?.l_name ? "error" : ""}
              />
            </div>
            <div className="flex contact-inputs direction-column gap-5">
              <InputField
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.PHONE_NUMBER.LABEL}
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.PHONE_NUMBER.PLACEHOLDER}
                name="phone_number"
                required
                disabled
                value={value?.phone_number}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
                className={error?.phone_number ? "error" : ""}
                icon={<WpIcon />}
              />
              <InputField
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.ALTERNATE_NUMBER.LABEL}
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.ALTERNATE_NUMBER.PLACEHOLDER}
                name="alternate_number"
                onChange={handleChange}
                className={error?.alternate_number ? "error" : ""}
                errorMessage={error?.alternate_number}
                type="phone"
                value={value?.alternate_number}
                maxLength={limit.phone_number}
                icon={<PhoneOutlinedIcon />}
              />
              <InputField
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.EMAIL_ADDRESS.LABEL}
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.EMAIL_ADDRESS.PLACEHOLDER}
                name="email"
                value={value?.email}
                onChange={handleChange}
                className={error?.email ? "error" : ""}
                errorMessage={error?.email}
                icon={<EmailOutlinedIcon />}
              />
            </div>
            <div className="flex contact-inputs direction-column gap-5">
              <InputField
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.COMPANY_NAME.LABEL}
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.COMPANY_NAME.PLACEHOLDER}
                name="company_name"
                value={value?.company_name}
                required
                onChange={handleChange}
                className={error?.company_name ? "error" : ""}
              />
              <InputField
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.PINCODE.LABEL}
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.PINCODE.PLACEHOLDER}
                name="pincode"
                value={value?.pincode}
                required
                onChange={handleChange}
                className={error?.pincode ? "error" : ""}
                errorMessage={error?.pincode_message}
              />
              <InputField
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.ADDRESS_LINE.LABEL}
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.ADDRESS_LINE.PLACEHOLDER}
                name="address_line"
                value={value?.address_line}
                onChange={handleChange}
                className={error?.address_line ? "error" : ""}
              />
              <InputField
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.LOCATION.LABEL}
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.LOCATION.PLACEHOLDER}
                name="location"
                value={value?.location}
                onChange={handleChange}
                required
                disabled
                className={error?.location ? "error" : ""}
              />
            </div>
            <div className="checkbox-input-container flex direction-column gap-2">
              <label htmlFor="promo_code" className="flex alignCenter gap-2 checkbox">
                <input type="checkbox" name="promo_code" id="promo_code" onChange={handleChange} checked={value?.promo_code || false} />
                <span className="label-text">{ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.CHECKBOX_INPUT_FIELDS.PROMOCODE.LABEL}</span>
              </label>
              {value?.promo_code ? <InputField
                name="code"
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.PROMOCODE.PLACEHOLDER}
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.PROMOCODE.LABEL}
                onChange={handleChange}
                maxLength={limit.promocode}
                required
                value={value?.code}
                className={error?.code ? "error" : ""}
                icon={<PromoCodeIcon />}
              /> : ""}
            </div>
            <div className="checkbox-input-container flex direction-column gap-2">
              <label htmlFor="gst" className="flex alignCenter gap-2 checkbox">
                <input type="checkbox" name="gst" id="gst" onChange={handleChange} checked={value?.gst || false} />
                <span className="label-text">{ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.CHECKBOX_INPUT_FIELDS.GST_CODE.LABEL}</span>
              </label>
              {value?.gst ? <InputField
                name="gst_number"
                placeholder={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.GST_CODE.PLACEHOLDER}
                label={ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS.GST_CODE.LABEL}
                onChange={handleChange}
                value={value?.gst_number}
                className={(error?.gst_number || error?.gst_number_message) ? "error" : ""}
                errorMessage={error?.gst_number_message}
                maxLength={limit.gst_number}
                required
              /> : ""}
            </div>
          </div>
          <div className="form-footer w-100">
            <Button
              type="button"
              className="subscribe-button primary w-100"
              onClick={handleSubmit}
              disabled={isLoading || checkIsDisabled()}
            >
              {ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.BUTTONS.MAKE_PAYMENT}
            </Button>
            <Button
              type="button"
              className="back-button w-100"
              onClick={handleClose}
            >
              {ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.BUTTONS.GO_BACK}
            </Button>
          </div>
        </div>
        <div className="summary-section w-100">
          <div className="summary-content flex direction-column gap-8">
            <div className="summary-header flex justifyBetween">
              <h3 className="title-3">{ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.SUMMARY}</h3>
              <button className="rounded-14 change-plan-button" onClick={handleClose}>
                {ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.CHANGE_PLAN}
              </button>
            </div>
            <div className="summary-body">
              <div className="flex alignCenter justifyBetween plan-data-container">
                <div className="about-plan-details flex gap-2">
                  <div className="img-container">
                    <img src={planDetailsSummary.data?.planDetails.image} alt={ALTER_TEXT.SUBSCRIPTION_PLAN_IMG} />
                  </div>
                  <div className="plan-text-container">
                    <h3 className="title-3 plan-title">{planDetailsSummary.data?.planDetails.planName}</h3>
                    <p className="caption plan-description">{planDetailsSummary.data?.planDetails?.supportiveText}</p>
                  </div>
                </div>
                <div className="plan-pricing-container">
                  <p className="plan-price flex alignCenter justifyCenter">
                    <span className="price">{formatRupees(Number(planDetailsSummary.data?.planDetails?.price) || 0, true, false)}</span>
                    <span className="time">&nbsp;{OPERATER_STRINGS.DIVISION}&nbsp;{COMMON_STRINGS.YEAR}</span>
                  </p>
                </div>
              </div>
              <div className="bill-summary-details flex direction-column gap-2">
                <div className="flex alignCenter justifyBetween subtotal">
                  <p className="subtotal__title">{ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.SUBTOTAL}</p>
                  <p className="subtotal__amount">{formatRupees(Number(planDetailsSummary.data?.billingDitails?.subtotal) || 0, true, false)}</p>
                </div>
                {planDetailsSummary.data?.billingDitails?.needGSTInvoice ?
                  <>
                    {Number(planDetailsSummary.data?.billingDitails?.CGSTInPercentage) ? <div className="flex alignCenter justifyBetween gst">
                      <p className="gst__title">{ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.CGST}</p>
                      <p className="gst__rate">{planDetailsSummary.data?.billingDitails?.CGSTInPercentage} {OPERATER_STRINGS.MODULO}</p>
                    </div> : <></>}
                    {Number(planDetailsSummary.data?.billingDitails?.SGSTInPercentage) ? <div className="flex alignCenter justifyBetween gst">
                      <p className="gst__title">{ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.SGST}</p>
                      <p className="gst__rate">{planDetailsSummary.data?.billingDitails?.SGSTInPercentage} {OPERATER_STRINGS.MODULO}</p>
                    </div> : <></>}
                    {Number(planDetailsSummary.data?.billingDitails?.IGSTInPercentage) ? <div className="flex alignCenter justifyBetween gst">
                      <p className="gst__title">{ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.IGST}</p>
                      <p className="gst__rate">{planDetailsSummary.data?.billingDitails?.IGSTInPercentage} {OPERATER_STRINGS.MODULO}</p>
                    </div> : <></>}
                  </>
                  :
                  <></>}
                <div className="flex alignCenter justifyBetween gst">
                  <p className="gst__title">{ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.APPLIED_DISCOUNT}</p>
                  <p className="gst__rate">{formatRupees(Number(planDetailsSummary.data?.billingDitails?.promocodeDiscount) || 0, true, false)}</p>
                </div>
                <div className="flex alignCenter justifyBetween grand-total">
                  <h3 className="title-3 title_text">{ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.GRAND_TOTAL}</h3>
                  <h3 className="total_amount">{formatRupees(Number(planDetailsSummary.data?.billingDitails?.grandTotal) || 0, true, false)}</h3>
                </div>
              </div>
              <p className="summary-text">
                {ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.SUMMARY_TEXT}
              </p>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default SubscribeForm;