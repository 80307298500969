import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Popover, Spin } from "antd";
import OneSignal from "react-onesignal";

import socket from "../../socket";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { detectDevice, getProfileIcon } from "../../utils/helper";
import { handleNotificationDrawer, setNotificationData } from "../../redux/slices/notificationSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { DownArrow, SettingOutlinedFilledIcon } from "../../assets/images/icons";
import CompanyListAndSetting from "../companyListAndSetting";
import { BellOutlinedIcon, BiizlineLogo } from "../../assets/images";
import CountCapsule from "../countCapsule";
import { getSubscriptionPayloadData } from "../../redux/services/subscription";
import { SubscriptionModules } from "../../utils/constant";
import "../../assets/styles/common.scss";

export const Header = () => {
  const [isToggleCompany, setToggleCompany] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState({
    isSetting: false,
  });
  const [notificationCount, setNotificationCount] = useState({
    newNotifications: "",
    totalNotifications: "",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [navItems, setNavItems] = useState([
    {
      icon: "",
      text: <p>Home</p>,
      url: "/dashboard",
      checked: true,
      isDisable: true,
    },
    {
      icon: "",
      text: <span className="flex justifyCenter alignCenter gap-2"><p>Orders</p></span>,
      url: "/orders",
      checked: true,
      isDisable: true,
    },
    {
      icon: "",
      text: <p>Special Price List</p>,
      url: "/special-price-list",
      checked: true,
    },
    {
      icon: "",
      text: <span className="flex justifyCenter alignCenter gap-2"><p>Connections</p></span>,
      url: "/connection",
      checked: true,
    },
    {
      icon: "",
      text: <p>Products</p>,
      url: "/products",
      checked: true,
      isDisable: true,
    },
  ]);

  const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector(state => state.common);
  const companyDetails = userDetails.data?.company;
  const { userId } = userDetails.data?.user || { userId: 0 };
  const token = localStorage.getItem("token");
  const navigate = useNavigate();


  useEffect(() => {
    if (detectDevice()?.type === "Desktop" && userId && token) {
      dispatch(getSubscriptionPayloadData({ module: SubscriptionModules.COMPANY }));
    }
  }, [dispatch, token, userId]);

  useEffect(() => {
    socket.emit("notificationHistory", companyDetails?.companyId);
    socket.emit("newNotificationCount", companyDetails?.companyId);
    socket.on("newNotificationCountResponse", (data: any) => {
      document.title = data ? `(${data}) Biizline` : "Biizline";
      setNotificationCount((prev) => ({
        newNotifications: data,
        totalNotifications: prev.totalNotifications,
      }));
    });

    socket.on("readNotificationResponse", (data: any) => {
      if (data?.isRead) {
        dispatch(setNotificationData(data));
        socket.emit("newNotificationCount", companyDetails?.companyId);
      } else {
        console.error("read notification error");
      }
    });

    socket.emit("allNotificationCount", companyDetails?.companyId);

    socket.on("allNotificationCountResponse", (data: any) => {
      setNotificationCount((prev) => ({
        newNotifications: prev.newNotifications,
        totalNotifications: data,
      }));
    });
    socket.on(`webDeviceLogedOut_${userId}`,(data) => {
      if(data){
        OneSignal.logout();
        dispatch({ type: "RESET_STATE" });
        // removeFCMToken();
        localStorage.clear();
        setTimeout(() => {
          navigate("/login", { state: { step: 0 } });
          // window.location.href = "/login";
        }, 50);
      }
    });

    return () => {
      socket.off("newNotificationCountResponse");
      socket.off("readNotificationResponse");
      socket.off("allNotificationCountResponse");
      socket.off(`webDeviceLogedOut_${userId}`);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, companyDetails?.companyId, userId, dispatch]);

  const handleToggleNotification = () => {
    socket.emit("notificationHistory", companyDetails?.companyId);
    dispatch(handleNotificationDrawer());
  };

  const handleOpenChange = () => {
    setToggleCompany(!isToggleCompany);
  };

  const handleChange = () => {
    setIsOpenPopover((prev) => ({
      ...prev,
      isSetting: !isOpenPopover.isSetting,
    }));
  };

  return (
    <>
      <header className="header flex alignCenter justifyBetween w-100">
        <div className="flex gap-4 justifyBetween alignCenter h-100 overflow-auto w-100 container">
          <NavLink to={"/"} className="logo-icon">
            <img src={BiizlineLogo} alt="Logo" loading="lazy"/>
          </NavLink>
          <nav className="navbar flex justifyStart">
            <ul className="flex gap-2 nav-items">
              {navItems && navItems?.map((item, index) => {
                return (
                  item.checked ? <li className="item relative" key={index}>
                    <NavLink to={item.url} className={"flex alignCenter justifyCenter"}>{item.icon}{item.text}</NavLink>
                  </li> : ""
                );
              })}
            </ul>
          </nav>
          <div className="flex alignCenter justifyEnd buttons-container gap-6 w-100">
            {Object.entries(companyDetails || {}).length ?
              <Popover
                onOpenChange={handleOpenChange}
                open={isToggleCompany}
                rootClassName="select-company-popover"
                content={() => CompanyListAndSetting({ handleOpenChange })}
                trigger="click"
                placement="bottomRight">
                <button className="selected-company-btn gap-1">
                  {userDetails.isLoading ? <div className="loading-container"><Spin /></div> :
                    <>
                      <div className="image-container">
                        {companyDetails?.profileThumbnailImg ? <img src={companyDetails.profileThumbnailImg} alt="companyImage" /> : getProfileIcon(companyDetails?.name || "")}
                      </div>
                      <span className="company-name">{companyDetails?.name}</span>
                      <div className={isToggleCompany ? "action-dropdown active" : "action-dropdown"}>
                        <DownArrow />
                      </div>
                    </>}
                </button>
              </Popover> : <></>}
            {(companyDetails || Object.entries(companyDetails || {})?.length) ?
              <button className="notification-icon flex alignCenter relative" onClick={() => handleToggleNotification()}>
                <BellOutlinedIcon />
                {notificationCount?.newNotifications ?
                  <CountCapsule
                    count={Number(notificationCount?.newNotifications)}
                    limit={10}
                  /> : <></>}
              </button> : <></>}
            {(companyDetails || Object.entries(companyDetails || {})?.length) ?
              <Popover
                onOpenChange={handleChange}
                open={isOpenPopover.isSetting}
                rootClassName="select-company-popover"
                content={() => CompanyListAndSetting({ handleOpenChange: handleChange, isSetting: true })}
                trigger="click"
                placement="bottomRight">
                <button className="setting-icon icon-button relative">
                  <SettingOutlinedFilledIcon />
                  {/* <CountCapsule count={0} /> */}
                </button>
              </Popover> : <></>}
          </div>
        </div>
      </header>
    </>
  );
};