import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getUserModulesActivity } from "../../redux/services/accountSetting";
import { useAppSelector } from "../../hooks/useAppSelector";
import { accessTable } from "../../redux/slices/accountSettingSlice";
import { ACCOUNT_SETTINGS, ERROR_STRINGS } from "../../utils/constant";

interface IAccessTableProps {
  setIsUpdated: (e: boolean) => void;
}

const AccessTable = ({ setIsUpdated }: IAccessTableProps) => {
  const [tableData, setTableData] = useState([]);

  const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector((state) => state.accountSetting);
  const params = useParams();

  useEffect(() => {
    if (params?.userId && !userDetails?.isLoading) {
      setTableData(userDetails?.data?.modules);
    } else if (!params?.userId) {
      try {
        dispatch(getUserModulesActivity()).then((result: any) => {
          setTableData(result?.payload?.data);
        }).catch(() => {
          console.error(ERROR_STRINGS.SOMETHING_WRONG);
        });
      } catch (error) {
        console.error(ERROR_STRINGS.SOMETHING_WRONG);
      }
    }
  }, [dispatch, params, userDetails]);

  useEffect(() => {
    const arr = tableData?.map((moduleData: any) => {
      return (
        {
          moduleId: moduleData?.moduleId,
          activities: moduleData?.activities?.map((activities: any) => {
            return (
              activities?.isActive
                ?
                { activityId: activities?.activityId, isActive: activities?.isActive }
                :
                { activityId: activities?.activityId, isActive: false }
            );
          },
          ),
        }
      );
    });
    dispatch(accessTable(arr));
  }, [tableData, dispatch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, moduleId: number) => {
    const { name, checked } = e.target;
    setIsUpdated(true);
    setTableData((prevTableData: any) =>
      prevTableData.map((moduleData: any) =>
        moduleData.moduleId === moduleId
          ? {
            ...moduleData,
            activities: moduleData?.activities?.map((activities: any) => {
              return (
                activities?.activityName === name
                  ?
                  { activityId: activities?.activityId, isActive: checked, activityName: activities?.activityName }
                  :
                  { activityId: activities?.activityId, isActive: activities?.activityId === 1 ? false : activities?.isActive || false, activityName: activities?.activityName }
              );
            },
            ),
          }
          : moduleData,
      ),
    );
  };

  const handleView = (id: number) => {
    setTableData((prevTableData: any) =>
      prevTableData.map((moduleData: any) =>
        moduleData.moduleId === id
          ? {
            ...moduleData,
            activities: moduleData?.activities?.map((activities: any) => {
              return (
                activities?.activityName === "View Only"
                  ?
                  { activityId: activities?.activityId, isActive: !activities?.isActive, activityName: activities?.activityName }
                  :
                  { activityId: activities?.activityId, isActive: activities?.isActive || false, activityName: activities?.activityName }
              );
            },
            ),
          }
          : moduleData,
      ),
    );
  };

  return (
    <div className="access-table-wrapper">
      <h3 className="title-3">{ACCOUNT_SETTINGS.ROLE_AND_ACCESS_PAGE.TABLE.TITLE}</h3>
      <div className="custom-table-wrapper">
        <div className="table-head">
          <p className="caption">{ACCOUNT_SETTINGS.ROLE_AND_ACCESS_PAGE.TABLE.HEADING}</p>
        </div>
        <div className="table-body">
          {tableData?.map((item: any, index: number) => (
            <div className="table-row flex gap-2" key={index}>
              <div className="table-data w-50">{item.moduleName}</div>
              <div className="flex gap-6 w-50">
                {item?.activities?.map((val: any, ind: number) => (
                  <div className="table-data flex gap-2" key={ind}>
                    <input
                      type="checkbox"
                      name={val.activityName}
                      id={`${item.moduleId}-${val.activityId}`} // Unique id
                      checked={val.isActive}
                      onChange={(e) => { val.activityId === 1 ? handleView(item?.moduleId) : handleChange(e, item?.moduleId); }}
                    />
                    <label htmlFor={`${item.moduleId}-${val.activityId}`} className="flex gap-2">
                      <span className="label-text">{val.activityName}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccessTable;
