import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import dayjs from "dayjs";

import CommonModal from "../../components/commonModal";
import StatusSwitch from "./statusSwitch";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getDispatchesList, updateStatusByorderDispatchId } from "../../redux/services/orders";
import { ERROR_STRINGS } from "../../utils/constant";

interface Props {
  open: boolean;
  close: () => void;
  orderId: number;
  orderNo: number
}

interface DataProps {
  orderDispatchId: number;
  status: string;
  trackingId: string;
  dispatchedOn: string;
}

const PartiallyRecievedModal = ({ open, close, orderId, orderNo }: Props) => {
  const [data, setData] = useState<DataProps[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    if (orderId) {
      dispatch(getDispatchesList(orderId)).then((result) => {
        if (result.payload.status === 200) {
          setData(result.payload.data);
        }
      }).finally(() => setLoading(false));
    }
  }, [dispatch, orderId]);

  const handlePartiallyDispatch = (id: number) => {
    setLoading(true);
    dispatch(updateStatusByorderDispatchId({ orderDispatchId: id, action: "COMPLETED" })).then((result) => {
      if (result.payload.status === 200) {
        dispatch(getDispatchesList(orderId)).then((result) => {
          if (result.payload.status === 200) {
            setData(result.payload.data);
          }
        }).catch((error) => console.error(ERROR_STRINGS.SOMETHING_WRONG));
      }
    }).finally(() => setLoading(false));
  };

  return (
    <CommonModal
      close={close}
      open={open}
      footer={true}
      onSave={close}
      saveButtonText="Okay"
      className="partially-recived-modal"
      saveBtn
      closeIcon
    >
      <div className="partially-recived-container">
        <div className="header-wrapper">
          <p>Order No. <span>{orderNo}</span></p>
        </div>

        {loading && <div className="loading-container"><Spin /></div> }
        {data.length ? <div className="partially-recived-data">
          {data?.map((item, index) => (
            <div key={index} className="partially-recived-card">
              <div className="partially-recived-card-content">
                <p className="partially-dispatched-title">Partially Dispatched ID: {item?.trackingId}</p>
                <p className="date-and-time">{dayjs(item.dispatchedOn).format("D MMM, YYYY | hh:mm a")}</p>
              </div>
              <div className="order-status">
                <StatusSwitch
                  status={item.status}
                  orderId={1054}
                  onDispatch={() => handlePartiallyDispatch(item.orderDispatchId)}
                  activeTab={"My-order"}
                  onCancel={() => []}
                  orderDetails
                  orderDispatchId={item.orderDispatchId}
                />
              </div>
            </div>
          ))}
        </div> : <></>}
      </div>
    </CommonModal>
  );
};

export default PartiallyRecievedModal;