import { message } from "antd";

const useMessageHook = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const showMessage = (
    type: "success" | "error" | "info" | "warning",
    content: string,
    duration = 3,
  ) => {
    messageApi.open({
      key,
      type,
      content,
      duration,
    });
  };

  return { showMessage, contextHolder };
};

export default useMessageHook;
