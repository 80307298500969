import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

import { BiizlineLogo } from "../../assets/images";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const ref: any = useRef();

  const navlinks = [
    {
      link: "#home",
      text: "Home",
    },
    {
      link: "#howitwork",
      text: "How it Work",
    },
    {
      link: "#features",
      text: "Features",
    },
    {
      link: "#about",
      text: "About us",
    },
    {
      link: "#contactus",
      text: "Contact Us",
    },
    // {
    //   link: "#testimonials",
    //   text: "Testimonials",
    // },
    // {
    //   link: "#pricing",
    //   text: "Pricing",
    // },
    // {
    //   link: "#blog",
    //   text: "Blog",
    // },
    // {
    //   link: "#contactus",
    //   text: "Contact us",
    // },
  ];

  const navHighlighter = () => {
    // Query sections after the component has mounted and rendered
    const sections = document.querySelectorAll("section[id]");
    const scrollY = window.pageYOffset;

    sections.forEach((current: any) => {
      const sectionHeight = current?.offsetHeight;
      const sectionTop = current?.offsetTop - 100;
      const sectionId = current?.getAttribute("id");

      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        document
          .querySelector(`.navlink-item a[href*=${sectionId}]`)
          ?.classList.add("active");
      } else {
        document
          .querySelector(`.navlink-item a[href*=${sectionId}]`)
          ?.classList.remove("active");
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.querySelector("body")?.classList.add("hidden");
    } else {
      document.querySelector("body")?.classList.remove("hidden");
    }
  }, [isOpen]);

  useEffect(() => {
    // Add scroll event listener after the first render
    window.addEventListener("scroll", navHighlighter);

    // Run the navHighlighter once to set the initial active link
    navHighlighter();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", navHighlighter);
    };
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  return (
    <header className="header-container">
      <div className="container flex justifyBetween alignCenter w-100">
        <div className="logo-container" onClick={() => { localStorage.clear(); navigate("/", { state: { step: 1 } }); }}>
          <Link to={"/home"}><img loading="lazy" src={BiizlineLogo} alt="logo" /></Link>
        </div>
        <nav className="navbar">
          <ul className="navlinks-list flex w-100 justifyBetween alignCenter gap-4">
            {
              navlinks.map((item) => {
                return (
                  <li className="navlink-item" key={item.link}>
                    <a href={`/home${item.link}`} id={item.link} ref={ref}>{item.text}</a>
                  </li>
                );
              })
            }
          </ul>
        </nav>
        <button
          className="login-button primary-button rounded-16"
          onClick={() => navigate("/login")}
        >
        Login
        </button>
        <button className="hamburger-icon" onClick={() => setIsOpen(true)}>
          <MenuOutlined />
        </button>
        <aside className={isOpen ? "mobile-navigation active" : "mobile-navigation"}>
          <div className="flex alignCenter justifyBetween h-80">
            <div className="logo-container" onClick={() => { localStorage.clear(); navigate("/", { state: { step: 1 } }); }}>
              <Link to={"/home"}><img src={BiizlineLogo} alt="logo" loading="lazy" /></Link>
            </div>
            <button className="hamburger-icon" onClick={() => setIsOpen(false)}>
              <CloseOutlined />
            </button>
          </div>
          <nav className="navbar">
            <ul className="navlinks-list flex w-100 justifyBetween alignCenter gap-4">
              {
                navlinks.map((item) => {
                  return (
                    <li className="navlink-item" key={item.link} onClick={() => setIsOpen(false)}>
                      <a href={`/home${item.link}`} id={item.link} ref={ref}>{item.text}</a>
                    </li>
                  );
                })
              }
            </ul>
            <button
              className="login-button primary-button rounded-16"
              onClick={() => navigate("/login")}
            >
            Login
            </button>
          </nav>
        </aside>
      </div>
    </header>
  );
};

export default Header;