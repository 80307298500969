import React, { useEffect, useRef, useState } from "react";

import { handleKeyDown } from "../../utils/helper";
import VerifyOtpModal from "../../pages/settings/verifyOtpModal";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMessageHook from "../../hooks/useMessageHook";

interface IProps {
  value?: string,
  placeholder: string,
  name: string,
  label?: string
  required?: boolean;
  type?: string;
  onChange?: any;
  className?: string | null;
  maxLength?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  showCount?: boolean;
  handleRemoveField?: () => void;
  suggetion?: string[];
  handleSuggetionClick?: (data: string) => void;
  verify?: "phone" | null;
  handleVerifyPhone?: any;
  icon?: any;
}

export const InputField = (
  {
    handleRemoveField,
    autoFocus = false,
    value,
    placeholder,
    name,
    label,
    required = false,
    onChange,
    className,
    maxLength = 50,
    type = "text",
    errorMessage,
    showCount = false,
    suggetion,
    handleSuggetionClick,
    verify,
    handleVerifyPhone,
    icon,
    ...props
  }: IProps) => {
  const [isFocus, setFocus] = useState(false);
  const [verifyValue, setVerifyValue] = useState(false);
  const { showMessage, contextHolder } = useMessageHook();

  const inputRef = useRef<HTMLInputElement>(null);
  const { otpSend } = useAppSelector((state) => state.setting);

  useEffect(() => {
    setTimeout(() => {
      if (autoFocus && inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  }, [autoFocus]);

  useEffect(() => {
    if (otpSend?.isSuccess && !otpSend?.isLoading) {
      showMessage("success", otpSend?.message);
      setVerifyValue(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpSend]);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent blur event when clicking inside result-container
  };

  const handleVerify = async (e: React.FormEvent) => {
    /^[6-9]\d{9}$/.test(value || "") ? await handleVerifyPhone() : showMessage("error", "Please Enter Valid Phone number");
  };



  return (
    <div className={className ? className + " input" : "input"}>
      {contextHolder}
      <div className={value?.length ? "input-container bg-light" : "input-container"}>
        <label htmlFor={name}>
          {label && (
            <div className="flex justifyBetween">
              <span className="flex gap-1">
                {label}
                {required && <span className="require-icon"> *</span>}
              </span>
              {handleRemoveField ? <button className="remove-field" onClick={() => handleRemoveField()}>Remove</button> : <></>}
            </div>
          )}
          <div className={verify ? "flex input-wrapper alignCenter" : ""}>
            <div className={icon ? (props.disabled ? "flex icon-input alignCenter disabled" : "flex icon-input alignCenter") : ""}>
              {icon}
              <input
                ref={inputRef}
                value={value}
                type={type}
                name={name}
                placeholder={placeholder}
                {...props}
                onKeyDown={handleKeyDown}
                maxLength={maxLength}
                onChange={onChange}
                autoComplete="off"
                autoFocus={autoFocus}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
            </div>
            {(verify === "phone" && value?.length === 10) ? <div className="verify-button">
              <button type="button" className="verify" onClick={handleVerify}>Verify</button>
            </div> : ""}
          </div>
        </label>
        <div className="flex alignCenter justifyBetween">
          {errorMessage ? <p className="error-message">{errorMessage}</p> : ""}
          {showCount ? <p className="total-char-count">{value?.length || inputRef.current?.value.length || 0}/{maxLength}</p> : ""}
        </div>

        {isFocus && suggetion && suggetion?.length && (value?.length || 0) > 0 ?
          <div className="suggesion-container" onMouseDown={handleMouseDown}>
            <ul>
              {suggetion.map((data, index) => <li key={index} onClick={() => handleSuggetionClick && handleSuggetionClick(data)}>{data}</li>)}
            </ul>
          </div> : <></>
        }

        {verify === "phone" && verifyValue ? <VerifyOtpModal close={() => setVerifyValue(false)} open={verifyValue} phoneNumber={value || ""} /> : ""}
      </div>
    </div>
  );
};