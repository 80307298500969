import { createSlice } from "@reduxjs/toolkit";

import {
  addProductsForCustomer,
  getCompanySpecialPriceDetailsById,
  getCustomersList,
  getCustomerProductSizes,
  getProductsList,
  updateSpecialPriceBulkRate,  
} from "../services/specialPriceList";
import { CustomerDataProps, SpecialPriceListProductProps } from "../../types/specialPriceList";
import { ProductList, ProductSizes } from "../../types/productsType";

interface InitialStateProps {
  customersList: {
    isLoading: boolean;
    isSuccess: boolean;
    data: CustomerDataProps[];
    message: string;
  };
  customerDetails: {
    isLoading: boolean;
    isSuccess: boolean;
    data: {
      specialPriceList: SpecialPriceListProductProps[];
      total: number
    };
    message: string;
  };
  productsList: {
    isLoading: boolean;
    isSuccess: boolean;
    data: ProductList[];
    message: string;
  };
  productSizes: {
    isLoading: boolean;
    isSuccess: boolean;
    data: ProductSizes[];
    message: string;
  };
  specialPriceList: {
    isLoading: boolean;
    isSuccess: boolean;
    data: SpecialPriceListProductProps[];
    message: string;
  };
  updateBulkRate: {
    isLoading: boolean;
  };
}

const initialState: InitialStateProps = {
  customersList: {
    isLoading: false,
    isSuccess: false,
    data: [],
    message: "",
  },
  customerDetails: {
    isLoading: false,
    isSuccess: false,
    data: {
      specialPriceList: [],
      total: 0,
    },
    message: "",
  },
  productsList: {
    isLoading: false,
    isSuccess: false,
    data: [],
    message: "",
  },
  productSizes: {
    isLoading: false,
    isSuccess: false,
    data: [],
    message: "",
  },
  specialPriceList: {
    isLoading: false,
    isSuccess: false,
    data: [],
    message: "",
  },
  updateBulkRate: {
    isLoading: false,
  },
};

const specialPriceListSlice = createSlice({
  name: "SpecialPriceList",
  initialState,
  reducers: {
    clearCompanyDetails: (state) => {
      state.customerDetails = initialState.customerDetails;
    },
    clearProductsData: (state) => {
      state.productsList = initialState.productsList;
      state.productSizes = initialState.productSizes;
    },
    updateSpecialPrice: (state, { payload }) => {
      state.customersList.data = payload;
    },
  },
  extraReducers: (builder) => {
    // get Customers List
    builder.addCase(getCustomersList.pending, (state) => {
      state.customersList.isLoading = true;
      state.customersList.isSuccess = false;
    });
    builder.addCase(getCustomersList.fulfilled, (state, { payload }: any) => {
      state.customersList.isSuccess = true;
      state.customersList.isLoading = false;
      state.customersList.data = payload?.data;
      state.customersList.message = payload?.message;
    });
    builder.addCase(getCustomersList.rejected, (state, { payload }: any) => {
      state.customersList.data = payload;
      state.customersList.isSuccess = false;
      state.customersList.isLoading = false;
      state.customersList.message = payload?.message;
    });

    // get special pricelist details by customer id
    builder.addCase(getCompanySpecialPriceDetailsById.pending, (state) => {
      state.customerDetails.isLoading = true;
      state.customerDetails.isSuccess = false;
    });
    builder.addCase(getCompanySpecialPriceDetailsById.fulfilled, (state, { payload }: any) => {
      state.customerDetails.isSuccess = true;
      state.customerDetails.isLoading = false;
      state.customerDetails.data = payload?.data;
      state.customerDetails.message = payload?.message;
    });
    builder.addCase(getCompanySpecialPriceDetailsById.rejected, (state, { payload }: any) => {
      state.customerDetails.data = payload;
      state.customerDetails.isSuccess = false;
      state.customerDetails.isLoading = false;
      state.customerDetails.message = payload?.message;
    });

    // get list of products by company
    builder.addCase(getProductsList.pending, (state) => {
      state.productsList.isLoading = true;
      state.productsList.isSuccess = false;
      state.productsList.data = [];
    });
    builder.addCase(getProductsList.fulfilled, (state, { payload }: any) => {
      state.productsList.isSuccess = true;
      state.productsList.isLoading = false;
      state.productsList.data = payload?.data;
      state.productsList.message = payload?.message;
    });
    builder.addCase(getProductsList.rejected, (state, { payload }: any) => {
      state.productsList.data = payload;
      state.productsList.isSuccess = false;
      state.productsList.isLoading = false;
      state.productsList.message = payload?.message;
    });

    // get list of product sizes
    builder.addCase(getCustomerProductSizes.pending, (state) => {
      state.productSizes.isLoading = true;
      state.productSizes.isSuccess = false;
    });
    builder.addCase(getCustomerProductSizes.fulfilled, (state, { payload }: any) => {
      state.productSizes.isSuccess = true;
      state.productSizes.isLoading = false;
      state.productSizes.data = payload?.data?.productSizes;
      state.productSizes.message = payload?.message;
    });
    builder.addCase(getCustomerProductSizes.rejected, (state, { payload }: any) => {
      state.productSizes.data = payload;
      state.productSizes.isSuccess = false;
      state.productSizes.isLoading = false;
      state.productSizes.message = payload?.message;
    });

    // get customer products special price list
    builder.addCase(addProductsForCustomer.pending, (state) => {
      state.specialPriceList.isLoading = true;
      state.specialPriceList.isSuccess = false;
    });
    builder.addCase(addProductsForCustomer.fulfilled, (state, { payload }: any) => {
      state.specialPriceList.isSuccess = true;
      state.specialPriceList.isLoading = false;
      state.specialPriceList.data = payload?.data;
      state.specialPriceList.message = payload?.message;
    });
    builder.addCase(addProductsForCustomer.rejected, (state, { payload }: any) => {
      state.specialPriceList.data = payload;
      state.specialPriceList.isSuccess = false;
      state.specialPriceList.isLoading = false;
      state.specialPriceList.message = payload?.message;
    });
    
    // Update BulkRates
    builder.addCase(updateSpecialPriceBulkRate.pending, (state) => {
      state.updateBulkRate.isLoading = true;
    });
    builder.addCase(updateSpecialPriceBulkRate.fulfilled, (state, { payload }: any) => {
      state.updateBulkRate.isLoading = false;
    });
    builder.addCase(updateSpecialPriceBulkRate.rejected, (state, { payload }: any) => {
      state.updateBulkRate.isLoading = false;
    });
  },
});

export const { clearCompanyDetails, clearProductsData, updateSpecialPrice } = specialPriceListSlice.actions;
export default specialPriceListSlice.reducer;